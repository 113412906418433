export enum HttpStatus {
  Success = 200,
  Created = 201,
  NoContent = 204,
  NotAuthorized = 401,
  NotFound = 404,
  InternalServerError = 500,
}

export enum BrowserRoutes {
  HOME = '/home',
  AUTH = '/auth',
  FORGOT_PASSWORD = '/auth/forgot-password',
  RESET_PASSWORD = '/auth/forgot-password/:mail/:token/:locale',
  NOT_FOUND = '/404',
  GROWERS_SETTINGS = '/admin-area/grower-settings',
  USERS_SETTINGS = '/admin-area/users-settings',
  LIVE_MONITORING = '/admin-area/live-monitoring',
  CROP_MODELS = '/agronomist-area/crop-models',
  COUPONS = '/admin-area/coupons',
}

export enum TabViewMode {
  Table = 'Table',
  Map = 'Map',
}

export enum Locales {
  English = 'en',
  Spanish = 'es',
  Hebrew = 'he',
  French = 'fr',
  Arabic = 'ar',
}

export enum SupportCouponCountries {
  'IL' = 'IL',
  'ES' = 'ES',
  'MX' = 'MX',
  'MA' = 'MA',
  'IN' = 'IN',
  'AU' = 'AU',
  'FR' = 'FR',
}
