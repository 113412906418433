import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { observer } from 'mobx-react';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { ActionMode, DialogOnCloseReason } from 'models/shared';
import styled from 'styled-components';
import { JSONSchema } from 'stores/types/types';
import PlotSettingsForm from './PlotSettingsForm';

// Code to generate default values for missing PARAMS in form, in case we will need it later
const generateDefaults = (
  properties: JSONSchema,
  parameters: { [key: string]: any } = {},
) => {
  Object.entries(properties).forEach(([key, property]) => {
    if (property.type === 'boolean') {
      parameters[key] = parameters[key] ?? false;
    } else if (property.type === 'object') {
      const newParams: { [key: string]: any } = parameters[key] || {};
      parameters[key] = newParams;
      generateDefaults(property.properties, newParams);
    } else {
      parameters[key] = parameters[key] ?? property.default;
    }
  });

  return parameters;
};

const setRecursiveValue = (
  valueKey: string[],
  valueObject: { [key: string]: any },
  value: any,
) => {
  if (!valueKey.length) {
    return;
  }

  if (valueKey.length === 1) {
    valueObject[valueKey[0]] = value;
    return;
  }

  if (valueKey.length > 1) {
    setRecursiveValue(valueKey.slice(1), valueObject[valueKey[0]], value);
  }
};

interface PlotSettingsManagementDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const StyledDialogContent = styled(DialogContent)`
  padding: 0 1rem;
`;

const PlotSettingsManagementDialog: FC<PlotSettingsManagementDialogProps> =
  observer(({ visible = false, onHide, title, mode }) => {
    const { t } = useTranslation('plot');
    const { plotsStore, userStore, resellersStore } = useStores();
    const formValuesRef = useRef<any>(null);

    const [initialUserActionString, setInitialUserActionString] = useState('');

    const [isSaving, setIsSaving] = useState(false);

    const [values, setValues] = useState(plotsStore.plotParams?.params);
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

    const hasError = useMemo(
      () => Object.values(errors).some((error) => error),
      [errors],
    );

    // ValueKey uses a string of keys for the value that changed , for example {item1:{item2:{item3: value}}}
    // returns ['item1', 'item2', 'item3']
    const handleChangeValue = (
      valueKey: string[],
      value: any,
      error: boolean,
    ) => {
      const finalValueKey = valueKey[valueKey.length - 1];
      const keyInErrors = Object.keys(errors).includes(finalValueKey);
      const shouldSetError = (error && !keyInErrors) || (!error && keyInErrors);
      if (shouldSetError) {
        setErrors((prev) => {
          if (keyInErrors && error === false) {
            const newErrors = { ...prev };
            delete newErrors[finalValueKey];
            return newErrors;
          }

          return {
            ...prev,
            [valueKey[valueKey.length - 1]]: error,
          };
        });
      }

      const newFormValues = JSON.parse(
        JSON.stringify({ ...formValuesRef.current }),
      );

      const temp = newFormValues;
      setRecursiveValue(valueKey, temp, value);
      formValuesRef.current = temp;
    };

    useEffect(() => {
      if (
        initialUserActionString ||
        JSON.stringify(plotsStore.plotAction) === '{}'
      ) {
        return;
      }

      setInitialUserActionString(JSON.stringify(plotsStore.plotAction));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plotsStore.plotAction]);

    useEffect(() => {
      if (plotsStore.plotParams?.params) {
        setValues(plotsStore.plotParams?.params);
        formValuesRef.current = plotsStore.plotParams?.params;
      }
    }, [plotsStore.plotParams?.params]);

    const closeDialog = useCallback(() => {
      setInitialUserActionString('');
      onHide();
    }, [onHide]);

    const handleCloseDialog = useCallback(
      (event: unknown, reason: DialogOnCloseReason) => {
        if (reason && reason === DialogOnCloseReason.BackDropClick) {
          return;
        }

        plotsStore.setPlotParams(undefined);
        closeDialog();
      },
      [closeDialog, plotsStore],
    );

    const handleSubmitClick = useCallback(async () => {
      setIsSaving(true);
      const plotParams = formValuesRef.current;
      const plotId = resellersStore.selectedRowsPlots?.[0]?.id;
      const userName = userStore.user?.email || '';
      const res = await plotsStore.postPlotParams(plotId, userName, plotParams);
      setIsSaving(false);
      if (res) {
        plotsStore.setPlotParams(undefined);
        closeDialog();
      }
    }, [
      closeDialog,
      plotsStore,
      resellersStore.selectedRowsPlots,
      userStore.user?.email,
    ]);

    return (
      <Dialog
        open={visible}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <StyledDialogContent>
          {plotsStore.plotParams?.schema && values && (
            <PlotSettingsForm
              handleChangeValue={handleChangeValue}
              properties={plotsStore.plotParams?.schema?.properties}
              definitions={plotsStore.plotParams?.schema?.definitions}
              values={values}
            />
          )}
        </StyledDialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleSubmitClick}
            disabled={hasError}
            loading={isSaving}
          >
            <Text textcolor={theme.color.white} size="sm">
              {t('edit')}
            </Text>
          </Button>
          <Button onClick={closeDialog}>
            <Text textcolor={theme.color.white} size="sm">
              {t('cancel')}
            </Text>
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

export default PlotSettingsManagementDialog;
