import Page from 'components/shared/Page';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState, useCallback, MouseEvent } from 'react';
import Grid from 'components/UI/Grid';
import { ToggleBox, ToggleBoxProps } from 'components/shared/ToggleBox';
import { VisibilityControl } from 'components/shared/VisibilityControl';
import VerticalCollapse from 'components/shared/VerticalCollapse';
import styled from 'styled-components';
import { TabPanel, TabView, TabViewTabChangeParams } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import i18next from 'i18next';
import { TabViewMode } from 'core/enums';
import Loading from 'components/UI/Loading/Loading';
import { GrowerType, ResellerTreeMode } from 'models/grower';
import ResellersTable from './ResellersTable/ResellersTable';
import MenuBar from './MenuBar';
import ResellersTree from './ResellersTree/ResellersTree';
import DetailsTable from './ResellerDetails/Details';
import ResellerPlots from './Plots/ResellerPlots';
import ResellerUsers from './Users/ResellerUsers';
import SystemsTable from './Systems/Systems';
import ResellersMap from './ResellersMap/ResellersMap';
import FeaturesTable from './Features/Features';

const StyledMarginGrid = styled(Grid)`
  margin: 1rem 1rem 0 1rem;
`;

const TabViewContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 1rem;
`;

const StyledToggleBox = styled(ToggleBox)`
  position: absolute;
  right: 32px;
  top: 8px;
  z-index: 1;
`;

export const TreeContainer = styled.div`
  height: calc(100vh - 106px);
  margin: 1rem;
  margin-right: 0;
  overflow: hidden;
`;

export enum ResellersGrowerTab {
  Details = 0,
  Plots = 1,
  Systems = 2,
  Features = 3,
  Growers = 4,
  Users = 5,
}

const plotsViewOptions: ToggleBoxProps['options'] = [
  { value: TabViewMode.Table, label: i18next.t('grower:table') },
  { value: TabViewMode.Map, label: i18next.t('grower:map') },
];

const Resellers: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { resellersStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState<ResellersGrowerTab>(
    ResellersGrowerTab.Details,
  );

  const [plotsViewMode, setPlotsViewMode] = useState<TabViewMode>(
    TabViewMode.Table,
  );

  const growerType = resellersStore.selectedTreeNode?.data
    ?.growerType as GrowerType;

  const onTabChange = useCallback((e: TabViewTabChangeParams) => {
    setActiveIndex(e.index);
  }, []);

  useEffect(() => {
    setActiveIndex(
      growerType === GrowerType.Reseller
        ? ResellersGrowerTab.Growers
        : ResellersGrowerTab.Plots,
    );
  }, [growerType]);

  useEffect(() => {
    resellersStore.initData().then(() => {
      setIsLoading(false);
    });

    return () => {
      resellersStore.setResellerTree([]);
    };
  }, [resellersStore]);

  const handlePlotsViewChange = useCallback(
    (_event: MouseEvent<HTMLElement>, value: TabViewMode) => {
      setPlotsViewMode((prev) => value ?? prev);
    },
    [],
  );

  if (isLoading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  const isPlotsViewToggleVisible =
    growerType === GrowerType.Grower &&
    activeIndex === ResellersGrowerTab.Plots;

  return (
    <Page>
      <Grid direction="column" align="stretch" justify="start" grow>
        <StyledMarginGrid align="stretch">
          <MenuBar selectedTab={activeIndex} />
        </StyledMarginGrid>
        <VerticalCollapse
          leftSideWidth="400px"
          leftComponent={
            <TreeContainer>
              <ResellersTree mode={ResellerTreeMode.All} />
            </TreeContainer>
          }
          rightComponent={
            <TabViewContainer>
              <TabView
                activeIndex={activeIndex}
                renderActiveOnly={false}
                onTabChange={onTabChange}
              >
                <TabPanel header={t('details')}>
                  <DetailsTable />
                </TabPanel>
                {growerType === GrowerType.Grower && (
                  <TabPanel header={t('plots')}>
                    <VisibilityControl
                      visible={plotsViewMode === TabViewMode.Table}
                    >
                      <ResellerPlots />
                    </VisibilityControl>
                    <VisibilityControl
                      visible={plotsViewMode === TabViewMode.Map}
                    >
                      <ResellersMap />
                    </VisibilityControl>
                  </TabPanel>
                )}
                {growerType === GrowerType.Grower && (
                  <TabPanel header={t('systems')}>
                    <SystemsTable />
                  </TabPanel>
                )}
                {growerType === GrowerType.Grower && (
                  <TabPanel header={t('features')}>
                    <FeaturesTable />
                  </TabPanel>
                )}
                {growerType === GrowerType.Reseller && (
                  <TabPanel header={t('growers')}>
                    <ResellersTable />
                  </TabPanel>
                )}
                <TabPanel header={t('users')}>
                  <ResellerUsers />
                </TabPanel>
              </TabView>
              {isPlotsViewToggleVisible && (
                <StyledToggleBox
                  value={plotsViewMode}
                  options={plotsViewOptions}
                  onChange={handlePlotsViewChange}
                />
              )}
            </TabViewContainer>
          }
        />
      </Grid>
    </Page>
  );
});

export default Resellers;
